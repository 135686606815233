const { createSlice } = require("@reduxjs/toolkit");

const InitialState = {
  order_id: 0,
  hold_bill_count: 0,
  tax_amt: 0,
  inv_num: 0,
};

const orderSlice = createSlice({
  name: "customer",
  initialState: InitialState,
  reducers: {
    addorder_id: (state, action) => {
      state.order_id = action.payload;
    },
    removeorder_id: (state, action) => {
      state.order_id = 0;
    },
    addhold_bill_count: (state, action) => {
      state.hold_bill_count += 1; // or state.hold_bill_count++ if that's your preference
    },
    
    inserthold_bill_count: (state, action) => {
      state.hold_bill_count = action.payload;
    },
    removehold_bill_count: (state, action) => {
      state.hold_bill_count = 0;
    },
    dechold_bill_count: (state, action) => {
      if (state.hold_bill_count > 0) {
        state.hold_bill_count -= 1; // Decrease by 1 if greater than 0
      }
    },
    addtax_amt: (state, action) => {
      state.tax_amt = action.payload;
    },
    removetax_amt: (state, action) => {
      state.tax_amt = 0;
    },
    add_inv_num: (state, action) => {
      state.inv_num = action.payload;
    },
  },
});

export const {
  addorder_id,
  removeorder_id,
  addhold_bill_count,
  removehold_bill_count,
  addtax_amt,
  removetax_amt,
  dechold_bill_count,
  inserthold_bill_count,
  add_inv_num,
} = orderSlice.actions;

export default orderSlice.reducer;
